<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loading">
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{ $t('pump_install.application_id') }} : </p>
                      </b-col>
                      <b-col lg="9" sm="12">
                         <p class="text-dark">{{ license.application_id }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{ $t('pump_install.applicants_name') }} : </p>
                      </b-col>
                      <b-col lg="9" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? license.name_bn : license.name }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{ $t('pump_install.license_no') }} : </p>
                      </b-col>
                      <b-col lg="9" sm="12">
                         <p class="text-dark">{{ license.license_no }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{ $t('pump_install.issue_date') }} : </p>
                      </b-col>
                      <b-col lg="9" sm="12">
                         <p class="text-dark">{{ license.issue_date | dateFormat }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{ $t('pump_install.attachment') }} : </p>
                      </b-col>
                      <b-col lg="9" sm="12">
                         <a :href="baseUrl + 'download-attachment?file=uploads/pump-installation/license/original/' + license.attachment">Show Attarchment</a>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { farSchLicenseShow } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getLicenseDetails()
      Object.freeze(tmp)
      this.license = tmp
    }
  },
  data () {
    return {
      loading: false,
      license: '',
      baseUrl: irriSchemeServiceBaseUrl
    }
  },
  methods: {
    async getLicenseDetails () {
      this.loading = true
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, `${farSchLicenseShow}/${this.$props.id}`)
      this.loading = false
      if (result.success) {
        this.license = result.data
      }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
