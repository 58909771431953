<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('pump_install.pump_install') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.application_id')"
              label-for="application_id"
            >
              <b-form-input
                id="application_id"
                v-model="search.application_id"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="search.name"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.scheme_typel')"
              label-for="scheme_type_id"
            >
              <b-form-select
                plain
                id="scheme_type_id"
                v-model="search.scheme_type_id"
                :options="SchemeTypeList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.organization')"
              label-for="org_id"
            >
              <b-form-select
                plain
                id="org_id"
                v-model="search.org_id"
                :options="orgList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.division')"
              label-for="far_division_id"
            >
              <b-form-select
                plain
                id="far_division_id"
                v-model="search.far_division_id"
                :options="divisionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.district')"
              label-for="far_district_id"
            >
              <b-form-select
                plain
                id="far_district_id"
                v-model="search.far_district_id"
                :options="districtList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.upazila')"
              label-for="far_upazilla_id"
            >
              <b-form-select
                plain
                id="far_upazilla_id"
                v-model="search.far_upazilla_id"
                :options="upazilaList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.union')"
              label-for="far_union_id"
            >
              <b-form-select
                plain
                id="far_union_id"
                v-model="search.far_union_id"
                :options="unionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.mobile')"
              label-for="far_mobile_no"
            >
              <b-form-input
                plain
                id="far_mobile_no"
                v-model="search.far_mobile_no"
                >
                </b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.father_name')"
              label-for="father_name"
            >
              <b-form-input
                plain
                id="father_name"
                v-model="search.father_name"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.mother_name')"
              label-for="mother_name"
            >
              <b-form-input
                plain
                id="mother_name"
                v-model="search.mother_name"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.nid')"
              label-for="nid"
            >
              <b-form-input
                plain
                id="nid"
                v-model="search.nid"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pump_install.pump_install') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="applications" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(application_id)="data">
                      {{ $n(data.item.application_id, {useGrouping: false}) }}
                    </template>
                    <template v-slot:cell(pump_type)="data">
                      {{ (this.i18n.locale === 'bn') ? data.item.pump_type_name_bn : data.item.pump_type_name }}
                    </template>
                    <template v-slot:cell(created_date)="data">
                      <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFormat}}</span>
                    </template>
                    <template v-slot:cell(pump_district_id)="data">
                      {{ getDistrictName(data.item.pump_district_id) }}
                    </template>
                    <template v-slot:cell(view_application)="data">
                      <a href="javascript:" class="btn_table_action table_action_status" v-b-modal.modal-7 title="Application View" @click="setRowId(data.item)"><i class="far fa-eye"></i></a>
                    </template>
                    <template v-slot:cell(attached_document_link)="data">
                      <a v-if="data.item.general_minutes !== null" :href="baseUrl + 'download-attachment?file=uploads/scheme-application/general-minutes/original/' + data.item.general_minutes" title="General Minute" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                      <a v-if="data.item.scheme_lands !== null" :href="baseUrl + 'download-attachment?file=uploads/scheme-application/scheme-lands/original/' + data.item.scheme_lands" title="Scheme Land" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                      <a v-if="data.item.scheme_map !== null" :href="baseUrl + 'download-attachment?file=uploads/scheme-application/scheme-map/original/' + data.item.scheme_map" title="Scheme Map"><i class="ri-download-cloud-fill"></i></a>
                    </template>
                    <template v-slot:cell(license)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-2 title="License View" @click="setRowId(data.item)"><i class="fas fa-eye"></i></a>
                    </template>
                    <template v-slot:cell(installation_status)="data">
                      <p class="text-dark">{{ getStatus(data.item.status) }}</p>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" v-if="data.item.status !== 14" v-b-modal.modal-1 title="Update Progress" @click="setRowId(data.item)"><i class="fas fa-edit"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="paginationDataLoad"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-1" size="xl" :title="$t('pump_install.update_progress')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <UpdateProgress :item="item" :key="id"/>
      </p>
    </b-modal>
    <b-modal id="modal-7" size="lg" :title="$t('pump_install.application_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <ApplicationDetails :id="id" :key="id"/>
      </p>
    </b-modal>
    <b-modal id="modal-2" size="lg" :title="$t('pump_install.license')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <License :id="id" :key="id"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import UpdateProgress from './UpdateProgress'
import ApplicationDetails from '../SchemeApplicationDetails'
import License from './License'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpInstallList } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
  name: 'UiDataTable',
  components: {
    UpdateProgress,
    ApplicationDetails,
    License
  },
  data () {
    return {
      applications: [],
      baseUrl: irriSchemeServiceBaseUrl,
      item: '',
      application: '',
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        application_id: '',
        name: '',
        scheme_type_id: 0,
        org_id: 0,
        far_division_id: 0,
        far_district_id: 0,
        far_upazilla_id: 0,
        far_union_id: 0,
        far_mobile_no: '',
        father_name: '',
        mother_name: '',
        nid: ''
      },
      id: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      status: ''
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    formTitle () {
       return (this.id === 0) ? this.$t('irrigation_config.scheme_type_entry') : this.$t('irrigation_config.scheme') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('pump_install.application_id'), class: 'text-center' },
        { label: this.$t('pump_install.scheme_manager'), class: 'text-center' },
        { label: this.$t('pump_install.pump_type'), class: 'text-center' },
        { label: this.$t('pump_install.district'), class: 'text-center' },
        { label: this.$t('pump_install.view_application'), class: 'text-center' },
        { label: this.$t('pump_install.attached_document_link'), class: 'text-center' },
        { label: this.$t('pump_install.license'), class: 'text-center' },
        { label: this.$t('pump_install.installation_status'), class: 'text-center' },
        { label: this.$t('pump_install.created_date'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'sch_man_name_bn' },
          { key: 'pump_type_name_bn' },
          { key: 'pump_district_id' },
          { key: 'view_application' },
          { key: 'attached_document_link' },
          { key: 'license' },
          { key: 'installation_status' },
          { key: 'created_date' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'sch_man_name' },
          { key: 'pump_type_name' },
          { key: 'pump_district_id' },
          { key: 'view_application' },
          { key: 'attached_document_link' },
          { key: 'license' },
          { key: 'installation_status' },
          { key: 'created_date' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    SchemeTypeList: function () {
      return this.$store.state.IrriConfig.commonObj.schemeTypeList.filter(item => item.status === 0)
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.far_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.far_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.far_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        far_division_id: this.authUser.office_detail.division_id,
        far_district_id: this.authUser.office_detail.district_id,
        far_upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    this.loadData()
  },
  methods: {
    resetId () {
      this.id = 0
    },
    paginationDataLoad () {
      this.loadData()
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          far_division_id: this.authUser.office_detail.division_id,
          far_district_id: this.authUser.office_detail.district_id,
          far_upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      this.loadData()
    },
    setRowId (item) {
      this.id = item.id
      this.item = item
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(irriSchemeServiceBaseUrl, pumpInstallList, params).then(response => {
        if (response.success) {
          this.applications = response.data.data
          this.applications = response.data.data
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getDistrictName (districtId) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      const district = districtList.find(district => district.value === districtId)
      if (this.$i18n.locale === 'bn') {
        return district.text_bn !== undefined ? district.text_bn : ''
      } else {
        return district.text_en !== undefined ? district.text_en : ''
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('pump_install.pending')
      } else if (status === 2) {
        return this.$t('pump_install.processing')
      } else if (status === 3) {
        return this.$t('pump_install.selected')
      } else if (status === 4) {
        return this.$t('pump_install.review')
      } else if (status === 5) {
        return this.$t('pump_install.reject')
      } else if (status === 6) {
        return this.$t('pump_install.current_status')
      } else if (status === 7) {
        return this.$t('irrigation_task.verified')
      } else if (status === 8) {
        return this.$t('pump_install.agreement')
      } else if (status === 9) {
        return this.$t('pump_install.requisition')
      } else if (status === 10) {
        return this.$t('pump_install.supply')
      } else if (status === 11) {
        return this.$t('pump_install.installation')
      } else if (status === 12) {
        return this.$t('pump_install.participation_fee')
      } else if (status === 13) {
        return this.$t('pump_install.close')
      } else if (status === 14) {
        return this.$t('pump_install.installed')
      } else if (status === 15) {
        return this.$t('pump_install.Send')
      } else if (status === 16) {
        return this.$t('pump_install.forwarded_to_nothi')
      }
    }
  }
}
</script>
