<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row class="text-center mb-4">
                <b-col lg="12" sm="12">
                  <p class="text-dark">{{ $t('pump_install.scheme_id') }} :  {{ $n(application.application_id, {useGrouping:false}) }}</p>
                  <p class="text-dark">{{ $t('pump_install.address') }} :  {{ getSchemeLocation(application) }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="5">
                          <ValidationProvider name="Assign Contractor" vid="assign_contractor" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              :label="$t('pump_install.assign_contractor')"
                              label-for="contractor_id"
                              slot-scope="{ valid, errors }"
                              >
                              <b-form-select
                                plain
                                v-model="contractor_id"
                                :options="contractors"
                                id="contractor_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="null" selected>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px">
                        <b-col lg="2">
                          <p class="text-dark">{{ $t('pump_install.step_name') }}</p>
                        </b-col>
                        <b-col lg="3">
                          <p class="text-dark">{{ $t('pump_install.start_date') }}</p>
                        </b-col>
                        <b-col lg="3">
                          <p class="text-dark">{{ $t('pump_install.end_date') }}</p>
                        </b-col>
                        <b-col lg="2">
                          <p class="text-dark">{{ $t('pump_install.note') }}</p>
                        </b-col>
                        <b-col lg="2">
                          <p class="text-dark">{{ $t('pump_install.note_bn') }}</p>
                        </b-col>
                      </b-row>
                      <b-row v-for="(progress_type, index) in progress_types" :key="index">
                        <b-col lg="2">
                          <p class="text-dark">{{ $i18n.locale === 'bn' ? progress_type.step_name_bn : progress_type.step_name }}</p>
                        </b-col>
                        <b-col lg="2">
                          <ValidationProvider vid="start_date">
                            <b-form-group
                              label-for="start_date"
                              slot-scope="{ valid, errors }"
                              >
                              <flat-pickr class="form-control"
                                  v-model="progress_type.far_pump_install[0].start_date"
                                  placeholder="Select Date"
                                  :readonly="progress_type.is_there ? true : false"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></flat-pickr>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2">
                          <ValidationProvider vid="end_date">
                            <b-form-group
                              label-for="end_date"
                              slot-scope="{ valid, errors }"
                              >
                                <flat-pickr class="form-control"
                                  v-model="progress_type.far_pump_install[0].end_date"
                                  placeholder="Select Date"
                                  :readonly="progress_type.is_there  ? true : false"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></flat-pickr>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="3">
                          <ValidationProvider vid="note">
                            <b-form-group
                              label-for="note"
                              slot-scope="{ valid, errors }"
                              >
                              <b-form-input
                                id="note"
                                placeholder="Enter note"
                                v-model="progress_type.far_pump_install[0].note"
                                :readonly="progress_type.is_there ? true : false"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="3">
                          <ValidationProvider vid="note_bn">
                            <b-form-group
                              label-for="note_bn"
                              slot-scope="{ valid, errors }"
                              >
                              <b-form-input
                                id="note_bn"
                                placeholder="Enter note (Bn)"
                                v-model="progress_type.far_pump_install[0].note_bn"
                                :readonly="progress_type.is_there ? true : false"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { getContractor, pumpInstallProgressUpdate, masterPumpInstallProgressTypes } from '../../api/routes'
import IrrigationMixin from '../../../../../mixins/irrigation-dropdown'
import flatpickr from 'flatpickr'

export default {
  name: 'UpdateProgress',
  mixins: [IrrigationMixin],
  props: ['item'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.$t('globalTrans.save'),
      application: '',
      scheme_application_id: this.$props.item.id,
      progress_types: [],
      contractors: [],
      contractor_id: null
    }
  },
  created () {
    this.application = this.$props.item
    this.getContractorList()
    this.getMasterPumpInstallProgressTypes()
  },
  computed: {
    getPumpType: function () {
      return this.$store.state.IrriConfig.commonObj.pumpType.filter(item => item.status === 0)
    },
    getHorsePower: function () {
      return this.$store.state.IrriConfig.commonObj.horsePower.filter(item => item.status === 0)
    },
    categoryList: function () {
      return this.$store.state.IrriConfig.commonObj.categoryList.filter(item => item.status === 0)
    },
    unitList: function () {
     return this.$store.state.IrriConfig.commonObj.unitList.filter(item => item.status === 0)
    }
  },
  methods: {
    async getMasterPumpInstallProgressTypes () {
      this.loading = true
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, masterPumpInstallProgressTypes + '/' + this.application.pump_type_id + '/' + this.application.application_type_id + '/' + this.application.id)
      if (result.success) {
        const tmp = result.data
        const data = tmp.map(el => {
          if (el.far_pump_install.length === 0) {
            el.far_pump_install = [
              {
                start_date: '',
                end_date: '',
                note: '',
                note_bn: ''
              }
            ]
          } else {
            this.contractor_id = el.far_pump_install[0].contractor_id
          }
          return el
        })
        this.progress_types = data
      }
      this.loading = false
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      const listData = this.progress_types.map(item => {
        const progressTypes = { contractor_id: this.contractor_id, scheme_application_id: this.scheme_application_id }
        return Object.assign({}, item, progressTypes)
      })
      this.progress_types = listData
      result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpInstallProgressUpdate, this.progress_types)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        if (result.status === 14) {
          // const notification = result.notification
          // this.$socket.emit('send-notification', notification)
        }
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-1')
      }
    },
    getSchemeLocation (item) {
      const location = []
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.pump_division_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpDivision.text_bn !== undefined ? tmpDivision.text_bn : '')
      } else {
        location.push(tmpDivision.text_en !== undefined ? tmpDivision.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.pump_district_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpDistrict.text_bn !== undefined ? tmpDistrict.text_bn : '')
      } else {
        location.push(tmpDistrict.text_en !== undefined ? tmpDistrict.text_en : '')
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.pump_upazilla_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpUpazila.text_bn !== undefined ? tmpUpazila.text_bn : '')
      } else {
        location.push(tmpUpazila.text_en !== undefined ? tmpUpazila.text_en : '')
      }
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.pump_union_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpUnion.text_bn, item.pump_mauza_no_bn, item.pump_jl_no_bn, item.pump_plot_no_bn)
      } else {
        location.push(tmpUnion.text_en, item.pump_mauza_no, item.pump_jl_no, item.pump_plot_no)
      }
      return location.join(', ')
    },
    async getContractorList () {
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, getContractor)
      const listContractor = result.data.map(item => {
        return { value: item.id, text: (this.$i18n.locale === 'bn') ? item.contractor_name_bn : item.contractor_name }
      })
      this.contractors = listContractor
    }
  }
}
</script>
